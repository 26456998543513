import { Flow } from "app/Flow"
import React, { useCallback, useContext, useEffect, useMemo } from "react"
import "twin.macro"
import { Tasks } from "./Tasks"
import PopOver from "app/PopOver"
import { Responsive } from "@clevertrack/shared"
import { DataValidation } from "./DataValidation"
import { InstallationContext } from "../context"
import { InstallationActions, InstallationTypes } from "../actions"
import { DeviceCalibration } from "./DeviceCalibration"
import { DeviceInformation } from "./DeviceInformation"
import { DeviceHealthValidation } from "./DeviceHealthValidation"
import { InstallationVerification } from "./InstallationVerification"
import {
  DeviceInformationModel,
  InstallationScreenEnum,
  InstallationStep,
  InstallationStepStateEnum,
} from "../types"
import { useFirestoreDevices } from "services/firestore/devices"
import { useFirestoreInstallationGuide } from "services/firestore/installationguide"
import cogoToast from "@clevertrackdk/cogo-toast"
import { DeviceStockStatusEnum } from "app/Devices/types"
import { useInstallation } from "../hooks"
import { DevicesContext } from "app/Devices/context"
import { DevicesActions, DevicesTypes } from "app/Devices/actions"
import { Uninstall } from "./Uninstall"
import { DeviceAuditLogEvent } from "app/AuditLog/audit.types"
import { UserContext } from "app/User/context"
import { AccountsContext } from "app/Account/context"

export const InstallationFlow: React.FC = ({ ...props }) => {
  const {
    state: { currentScreen, device, account, installationSteps },
    dispatch,
  } = useContext(InstallationContext)
  const {
    state: { user },
  } = useContext(UserContext)
  const {
    state: { account: userAccount },
  } = useContext(AccountsContext)
  const { dispatch: devicesDispatch } = useContext(DevicesContext)
  const {
    getInstallationStepsByImei,
    saveInstallationStepByImei,
  } = useFirestoreDevices()
  const { saveFirebaseDevice, newDeviceAuditLogEntry } = useFirestoreDevices()
  const { getAccountApiVehicles } = useInstallation()
  const { createInstallationGuideEntry } = useFirestoreInstallationGuide()

  const setCurrentScreen = (screen: InstallationScreenEnum | null) => {
    dispatch(InstallationActions(InstallationTypes.SetScreen, screen))
  }

  useEffect(() => {
    if (!device?.imei) return
    const deviceStatusChanged = +new Date()
    if (
      !installationSteps?.find((step) =>
        [
          InstallationStepStateEnum.NotStarted,
          InstallationStepStateEnum.Incomplete,
        ].includes(step.stepState)
      ) &&
      device.stockStatus !== DeviceStockStatusEnum.Installed
    ) {
      // Yay, installation completed!
      saveFirebaseDevice(
        {
          imei: device.imei.toString(),
          stockStatus: DeviceStockStatusEnum.Installed,
          statusUpdated: deviceStatusChanged,
        },
        device.imei.toString()
      ).then((res) => {
        if (res.result === "OK") {
          devicesDispatch(
            DevicesActions(DevicesTypes.UpdateDeviceByID, {
              device: {
                ...device,
                stockStatus: DeviceStockStatusEnum.Installed,
                statusUpdated: deviceStatusChanged,
              },
            })
          )
        }
      })
    } else {
      // Still work to do
      if (
        installationSteps.some(
          (step) => step.stepState === InstallationStepStateEnum.Incomplete
        ) &&
        device.stockStatus !== DeviceStockStatusEnum.Awaiting
      ) {
        // Only set the device in "Awaiting installation" state in case some step is incomplete. Otherwise, keep their old state.
        saveFirebaseDevice(
          {
            imei: device.imei.toString(),
            stockStatus: DeviceStockStatusEnum.Awaiting,
            statusUpdated: deviceStatusChanged,
          },
          device.imei.toString()
        ).then((res) => {
          if (res.result === "OK") {
            devicesDispatch(
              DevicesActions(DevicesTypes.UpdateDeviceByID, {
                device: {
                  ...device,
                  stockStatus: DeviceStockStatusEnum.Awaiting,
                  statusUpdated: deviceStatusChanged,
                },
              })
            )
          }
        })
      }
    }
  }, [installationSteps, device])

  const onSaveInstallationStep = useCallback(
    async (
      screen: InstallationScreenEnum,
      data: InstallationStep,
      apiSave: Promise<any> | Promise<any>[] | null,
      options
    ) => {
      try {
        if (device?.imei) {
          if (options?.autoSave) {
            await saveInstallationStepByImei(device?.imei, screen, data)
            const steps = (await getInstallationStepsByImei(
              device?.imei
            )) as InstallationStep[]
            dispatch(
              InstallationActions(InstallationTypes.SetInstallationSteps, {
                steps,
              })
            )
          } else {
            const res = await saveInstallationStepByImei(
              device?.imei,
              screen,
              data
            )
            const steps = (await getInstallationStepsByImei(
              device?.imei
            )) as InstallationStep[]
            if (res.result === "OK") {
              const isFinished = !steps.find((step) =>
                [
                  InstallationStepStateEnum.NotStarted,
                  InstallationStepStateEnum.Incomplete,
                ].includes(step.stepState)
              )
              if (apiSave) {
                const results = await apiSave
                if (!results.map((x) => x.result).some((res) => res !== "OK")) {
                  isFinished
                    ? cogoToast.success("Montering afsluttet 🎉")
                    : cogoToast.success("Oplysninger gemt")
                  setCurrentScreen(InstallationScreenEnum.Tasks)
                }
              } else {
                isFinished
                  ? cogoToast.success("Montering afsluttet 🎉")
                  : cogoToast.success("Oplysninger gemt")
                setCurrentScreen(InstallationScreenEnum.Tasks)
              }

              if (isFinished && steps) {
                // Save installation guide data
                const deviceInfoData = steps.find(
                  (x) => x.stepID === InstallationScreenEnum.DeviceInformation
                )?.data as DeviceInformationModel
                await createInstallationGuideEntry(
                  deviceInfoData?.vehicleBrand,
                  deviceInfoData?.vehicleModel,
                  deviceInfoData.vehicleYear,
                  device.id,
                  `${account?.id}/${device?.id}`
                )
              }

              if (
                isFinished &&
                device.stockStatus !== DeviceStockStatusEnum.Installed
              ) {
                await newDeviceAuditLogEntry(device?.imei?.toString(), {
                  event: DeviceAuditLogEvent.Installed,
                  timestamp: +new Date(),
                  user: `${user?.firstName} ${user?.lastName}`,
                  userID: user?.id ?? 0,
                  userAccount: userAccount?.name.toString() ?? "",
                  userAccountID: +userAccount?.id ?? 0,
                })
              }

              await getAccountApiVehicles(account.id)
              dispatch(
                InstallationActions(InstallationTypes.SetInstallationSteps, {
                  steps,
                })
              )
            }
          }
        }
      } catch (error) {
        cogoToast.error("Kunne ikke gemme oplysningerne.")
        throw new Error(error)
      }
    },
    [device, account, user, userAccount]
  )

  const screenSet = useMemo(() => {
    const screens = [
      {
        key: "tasks",
        children: (
          <Tasks
            onSelectScreen={(screen: InstallationScreenEnum | null) =>
              setCurrentScreen(screen)
            }
          />
        ),
        wrapper: (
          <PopOver
            fromRight
            show={
              currentScreen === InstallationScreenEnum.Tasks ||
              currentScreen !== null
            }
            zindex={2200}
            selector="#___wrapper"
            tw="absolute shadow-none overflow-hidden"
          />
        ),
      },
      {
        key: "uninstall",
        children: (
          <Uninstall
            onSelectScreen={(screen: InstallationScreenEnum | null) =>
              setCurrentScreen(screen)
            }
          />
        ),
        wrapper: (
          <PopOver
            fromBottom
            show={currentScreen === InstallationScreenEnum.Uninstall}
            zindex={2300}
            selector="#___wrapper"
            tw="absolute shadow-none overflow-hidden"
          />
        ),
      },
    ]

    if (installationSteps && installationSteps.length > 0) {
      screens.push(
        {
          key: "deviceCalibration",
          children: <DeviceCalibration onSave={onSaveInstallationStep} />,
          wrapper: (
            <PopOver
              fromRight
              show={currentScreen === InstallationScreenEnum.DeviceCalibration}
              zindex={2300}
              selector="#___wrapper"
              tw="absolute shadow-none overflow-hidden"
            />
          ),
        },
        {
          key: "deviceInformation",
          children: <DeviceInformation onSave={onSaveInstallationStep} />,
          wrapper: (
            <PopOver
              fromRight
              show={currentScreen === InstallationScreenEnum.DeviceInformation}
              zindex={2300}
              selector="#___wrapper"
              tw="absolute shadow-none overflow-hidden"
            />
          ),
        },
        {
          key: "installationVerification",
          children: (
            <InstallationVerification onSave={onSaveInstallationStep} />
          ),
          wrapper: (
            <PopOver
              fromRight
              show={
                currentScreen ===
                InstallationScreenEnum.InstallationVerification
              }
              zindex={2300}
              selector="#___wrapper"
              tw="absolute shadow-none overflow-hidden"
            />
          ),
        }
        /* {
          key: "dataValidation",
          children: <DataValidation />,
          wrapper: (
            <PopOver
              fromRight
              show={
                currentScreen === InstallationScreenEnum.DataValidation &&
                toggled
              }
              zindex={2300}
              selector="#___wrapper"
              tw="absolute shadow-none overflow-hidden"
            />
          ),
        },
        {
          key: "deviceValidation",
          children: <DeviceHealthValidation />,
          wrapper: (
            <PopOver
              fromRight
              show={
                currentScreen ===
                  InstallationScreenEnum.DeviceHealthValidation && toggled
              }
              zindex={2300}
              selector="#___wrapper"
              tw="absolute shadow-none overflow-hidden"
            />
          ),
        }, */
      )
    }

    return screens
  }, [currentScreen, installationSteps, device])

  return <Responsive phone={<Flow screenSet={screenSet} />} />
}
