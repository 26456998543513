import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import "twin.macro"
import {
  FlowHeader,
  ScreenHeader,
  StyledForm,
  StyledPopoverContentContainer,
} from "./components"
import {
  DeviceCalibrationModel,
  InstallationScreenEnum,
  InstallationStepStateEnum,
  InstallationStepType,
} from "../types"
import { useFormData } from "hooks/useFormData"
import { InstallationContext } from "../context"
import { DevicesContext } from "app/Devices/context"
import uniqBy from "lodash-es/uniqBy"
import {
  Button,
  ButtonGroup,
  Form,
  FormField,
  Icon,
  Input,
  Textarea,
  ValidationInput,
} from "@clevertrack/shared"
import { Select } from "lib/Select"
import { FormFieldToggle } from "components/FormFieldToggle"
import styled from "styled-components"
import tw from "twin.macro"
import Accordion from "lib/Accordion"
import { getDisplayKey } from "app/Devices/helper"
import { DisplayKeyEnum } from "app/Devices/types"
import format from "date-fns/format"
import {
  patchVehicleCalibration,
  patchVehicleFeatures,
} from "services/vehicles"
import { getInstallationStepState } from "./helper"
import debounce from "lodash-es/debounce"
import { useInstallation } from "../hooks"
import isEqual from "lodash-es/isEqual"
import { useRealtimeDeviceValues } from "services/realtime/vehicles"
import { InstallationActions, InstallationTypes } from "../actions"

const LogIcon = styled.span<{ showAnalogVoltageLog: boolean }>`
  svg {
    ${tw`ml-2 transition-transform origin-center inline-block`}
    ${(props) =>
      props.showAnalogVoltageLog
        ? `transform: rotate(90deg);`
        : `transform: rotate(0deg);`}
  }
`

export const DeviceCalibration: React.FC<InstallationStepType> = ({
  onSave,
  ...props
}) => {
  const {
    state: {
      device,
      currentDeviceValues,
      installationSteps,
      fotaDevice,
      currentScreen,
    },
    dispatch,
  } = useContext(InstallationContext)
  const { getDeviceValuesByID } = useRealtimeDeviceValues()
  const [currentModel, updateModel, resetModel, setCurrentModel] = useFormData<
    DeviceCalibrationModel
  >(
    installationSteps?.find(
      (step) => step.stepID === InstallationScreenEnum.DeviceCalibration
    )?.data
  )
  const {
    state: { files },
  } = useContext(DevicesContext)
  const [analogVoltageLog, setAnalogVoltageLog] = useState<
    { time: string; value: string }[]
  >([])
  const [showAnalogVoltageLog, setShowAnalogVoltageLog] = useState(false)
  const { setCurrentScreen } = useInstallation()

  const unsubscribeToDevice = useMemo(() => {
    if (device && device.id) {
      return getDeviceValuesByID(device?.id.toString(), (snapshot) =>
        dispatch(
          InstallationActions(InstallationTypes.SetCurrentDeviceValues, {
            currentDeviceValues: snapshot.val(),
          })
        )
      )
    }
    return null
  }, [device])

  useEffect(() => {
    if (!device && unsubscribeToDevice) {
      // unsubscribe
      unsubscribeToDevice()
    }
  }, [device, unsubscribeToDevice])

  const stepData = useMemo(() => {
    if (installationSteps) {
      const step = installationSteps.find(
        (step) => step.stepID === InstallationScreenEnum.DeviceCalibration
      )
      if (step) {
        return step
      }
      return null
    }
    return null
  }, [installationSteps])

  const autoSaveFunc = useRef(
    debounce((newData) => {
      onSave(InstallationScreenEnum.DeviceCalibration, newData, null, {
        autoSave: true,
      })
    }, 2000)
  )

  useMemo(() => {
    if (
      currentModel &&
      currentScreen === InstallationScreenEnum.DeviceCalibration &&
      !isEqual(stepData?.data, currentModel)
    ) {
      const newData = {
        ...stepData,
        data: currentModel,
        stepState: InstallationStepStateEnum.Incomplete,
      }
      autoSaveFunc.current.cancel()
      autoSaveFunc.current(newData)
    }
  }, [currentModel, autoSaveFunc, stepData, currentScreen])

  const [availableConfigs, selectedConfig] = useMemo(() => {
    if (fotaDevice) {
      const avail = uniqBy(
        files?.filter((x) => x.name.includes(device?.deviceTypeName)),
        "id"
      ).map((x) => ({
        label: x.name,
        value: x.id,
      }))
      const selected = avail.find((x) => x.name === fotaDevice.configuration)
      return [avail, selected]
    }
    return [null, null]
  }, [files, device, fotaDevice])

  const onSubmitHandler = () => {
    if (autoSaveFunc.current) autoSaveFunc.current.cancel()
    const newData = {
      ...stepData,
      data: currentModel,
      stepState: getInstallationStepState(
        currentModel,
        stepData.optionalDataProps
      ),
    }
    const apiSave: Promise<any>[] = []
    apiSave.push(
      patchVehicleCalibration(
        {
          note: device.note,
          note2: device.note2,
          name: device.name,
          description: device.description,
          mileage: currentModel.totalDistance,
          totalEngineHours: currentModel.totalEngineHours,
        },
        device.id
      )
    )

    if (device.idleReadInput !== 0) {
      apiSave.push(
        patchVehicleFeatures(device?.id, {
          ...device.features,
          lowerIdleRPM: currentModel.lowerIdleRPM,
          zeroIdleAIN1: currentModel.zeroIdleAIN1,
          lowerIdleAIN1: currentModel.lowerIdleAIN1,
          engineLoadLow: currentModel.engineLoadLow,
        })
      )
    }

    onSave(
      InstallationScreenEnum.DeviceCalibration,
      newData,
      Promise.all(apiSave)
    )
  }

  const renderLowerIdleRPM = () => {
    return (
      <FormField label="Grænseværdi ml. tomgang og arbejde (RPM)">
        <Input
          type="number"
          min="0"
          placeholder="Indtast motoromdrejninger"
          defaultValue={currentModel?.lowerIdleRPM || ``}
          onChange={(e) => updateModel("lowerIdleRPM", e.target.value)}
        />
      </FormField>
    )
  }

  const renderEngineLoadLow = () => {
    return (
      <>
        <FormField label="Grænseværdi ml. tomgang og arbejde (%)">
          <Input
            type="number"
            min="0"
            max="100"
            step=""
            placeholder="Indtast motorbelastning"
            defaultValue={currentModel?.engineLoadLow || ``}
            onChange={(e) => updateModel("engineLoadLow", e.target.value)}
          />
        </FormField>
      </>
    )
  }

  const renderPressureTransmitterSettings = () => {
    return (
      <>
        <div tw="flex justify-between text-lg mb-0 mt-4 pb-4 px-4">
          <span tw="block">
            <span>Nuværende spænding: </span>
            <span tw="text-green-600">
              {device?.analog1Voltage > 0
                ? (device?.analog1Voltage / 1000).toFixed(3)
                : "0.000"}{" "}
              volt
            </span>
          </span>
          <span
            tw="block ml-auto cursor-pointer transition-colors hover:(text-brand-500)"
            onClick={() => setShowAnalogVoltageLog((prev) => !prev)}
          >
            {showAnalogVoltageLog ? `Skjul målinger` : `Se målinger`}
            <LogIcon {...{ showAnalogVoltageLog }}>
              <Icon icon="chevron-right" size="xs" />
            </LogIcon>
          </span>
        </div>
        <Accordion toggled={showAnalogVoltageLog}>
          <div tw="pb-4 px-4">
            {analogVoltageLog
              .sort((a, b) => (a.time > b.time ? -1 : 1))
              .map(({ time, value }, index) => {
                const colorStr = tw`text-green-600`
                return (
                  <span key={`log_${time}_${index}`} tw="text-lg block">
                    <span tw="inline-block mr-24">{`Kl. ${time}:`}</span>
                    <span css={[colorStr]} tw="-ml-1">
                      {(+value / 1000).toFixed(3)} volt
                    </span>
                  </span>
                )
              })}
          </div>
        </Accordion>
        <FormField label="Grænseværdi ml. tomgang og arbejde (volt)">
          <Input
            type="number"
            min="0"
            step=".01"
            placeholder="Indtast volt"
            defaultValue={currentModel?.lowerIdleAIN1 || ``}
            onChange={(e) => updateModel("lowerIdleAIN1", e.target.value)}
          />
        </FormField>
        <FormField label="Grænseværdi ml. tænding og tomgang (volt)">
          <Input
            type="number"
            min="0"
            step=".01"
            placeholder="Indtast volt"
            defaultValue={currentModel?.zeroIdleAIN1 || ``}
            onChange={(e) => updateModel("zeroIdleAIN1", e.target.value)}
          />
        </FormField>
      </>
    )
  }

  useEffect(() => {
    if (currentDeviceValues) {
      const timestamp = getDisplayKey(
        currentDeviceValues.values,
        DisplayKeyEnum.LastConfirmationTime
      )

      if (timestamp && timestamp.value) {
        const time = format(new Date(timestamp.value), "HH:mm:ss")
        const newAnalogVoltageLog = uniqBy(
          [
            {
              time,
              value: currentDeviceValues.analog1Voltage,
            },
            ...analogVoltageLog,
          ],
          "time"
        ).slice(0, 10)

        setAnalogVoltageLog(newAnalogVoltageLog)
      }
    }
  }, [currentDeviceValues])

  const renderIdleCalibration = () => {
    switch (device?.idleReadInput) {
      case 1:
      case 3:
        return renderLowerIdleRPM()
      case 2:
        return renderPressureTransmitterSettings()
      case 4:
        return renderEngineLoadLow()
    }
  }

  return (
    <StyledPopoverContentContainer>
      <FlowHeader />
      <ScreenHeader>Kalibrering</ScreenHeader>
      <StyledForm onSubmit={onSubmitHandler} {...props}>
        <div tw="p-4 space-y-8 pb-16">
          <FormField label="Konfiguration">
            <Select
              isDisabled
              menuPlacement="auto"
              placeholder="Vælg konfiguration"
              options={availableConfigs}
              onChange={(opt) => updateModel("deviceConfiguration", opt.value)}
              value={selectedConfig}
              tw="pt-4"
            />
          </FormField>
          <FormFieldToggle
            toggleQuestion="Er der ingen motortimer tæller?"
            untoggleText="Fjern feltet"
            toggleText="Tilføj motortimer +"
            onDisable={() => {
              const { totalEngineHours, ...newModel } = currentModel
              setCurrentModel(newModel)
            }}
            onEnable={() => {
              updateModel(
                "totalEngineHours",
                stepData.data?.totalEngineHours ?? null
              )
            }}
            defaultToggled
          >
            <FormField label="Motortimer" validationKey="engineHours">
              <ValidationInput
                id="engineHours"
                defaultValue={currentModel.totalEngineHours}
                placeholder="Indtast motortimer"
                type="number"
                onChange={(e) =>
                  updateModel("totalEngineHours", e.target.value)
                }
              />
            </FormField>
          </FormFieldToggle>
          <FormFieldToggle
            toggleQuestion="Er der ingen kilometertæller?"
            untoggleText="Fjern feltet"
            toggleText="Tilføj kilometertal +"
            onDisable={() => {
              const { totalDistance, ...newModel } = currentModel
              setCurrentModel(newModel)
            }}
            onEnable={() => {
              updateModel("totalDistance", stepData.data?.totalDistance ?? null)
            }}
            defaultToggled
          >
            <FormField label="Kilometertæller" validationKey="totalDistance">
              <ValidationInput
                id="totalDistance"
                defaultValue={currentModel.totalDistance}
                placeholder="Indtast kørte kilometer"
                type="number"
                onChange={(e) => updateModel("totalDistance", e.target.value)}
              />
            </FormField>
          </FormFieldToggle>
          <Accordion
            toggled={
              typeof currentModel?.totalDistance === `undefined` &&
              typeof currentModel?.totalEngineHours === `undefined`
            }
          >
            <FormField label="Angiv årsag til manglende kalibreringsdata">
              <Textarea
                required={
                  typeof currentModel?.totalDistance === `undefined` &&
                  typeof currentModel?.totalEngineHours === `undefined`
                }
                defaultValue={currentModel?.noCalibrationDataNote}
                placeholder="Indtast årsag"
                onChange={(e) =>
                  updateModel("noCalibrationDataNote", e.target.value)
                }
              />
            </FormField>
          </Accordion>

          {device?.idleReadInput !== 0 && (
            <div>
              <h4 tw="pt-4 px-4 mt-0 bg-white">Tomgangskalibrering</h4>
              {renderIdleCalibration()}
            </div>
          )}
        </div>
        <ButtonGroup sticky="bottom" tw="bg-white px-4">
          <Button
            type="button"
            variant="cancel"
            onClick={() => setCurrentScreen(InstallationScreenEnum.Tasks)}
          >
            <span tw="flex items-center">
              <Icon icon="chevron-left" tw="w-4 h-4 mr-2" />
              <span tw="text-xl font-normal">Tilbage</span>
            </span>
          </Button>
          <Button
            type="submit"
            variant="primary"
            disabled={
              stepData &&
              getInstallationStepState(
                currentModel,
                stepData?.optionalDataProps
              ) !== InstallationStepStateEnum.Completed
            }
          >
            Gem
          </Button>
        </ButtonGroup>
      </StyledForm>
    </StyledPopoverContentContainer>
  )
}
