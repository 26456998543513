import React, { useContext, useMemo, useState } from "react"
import {
  Button,
  ButtonGroup,
  Form,
  FormContext,
  FormField,
  Tag,
  ValidationInput,
} from "@clevertrack/shared"
import tw from "twin.macro"
import styled from "styled-components"
import { useFormData } from "hooks/useFormData"
import { Select } from "lib/Select"
import Checkbox from "lib/Checkbox"
import { UserTypeEnum, UserTypes } from "../types"
import { resolveAvailableFeatures, userType } from "../helper"
import { AccountsContext } from "app/Account/context"
import { ApiAccountTypeEnum } from "app/Account/types"
import { AccountTypes, AccountsActions } from "app/Account/actions"
import { navigate } from "gatsby"

const StyledListItem = styled.li`
  ${tw`flex items-center justify-between`}
`

const StyledForm = styled(Form)`
  display: grid;
  row-gap: 2rem;
  padding-bottom: 6.4rem;

  .group {
    ${tw`p-8 lg:(p-0)`}
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 4rem;
  `}
`

type UserFormProps = {
  user: Partial<UserTypes>
  onSubmit: (user: UserTypes) => void
  saveButtonText?: string
  showSettings?: boolean
  basicTitle?: string
  settingsTitle?: string
}

export const UserForm: React.FC<UserFormProps> = ({
  user,
  onSubmit,
  saveButtonText = "Gem",
  showSettings,
  basicTitle = "Stamdata",
  settingsTitle = "Opsætning",
  ...props
}) => {
  const { state } = useContext(FormContext)
  const [currentUser, updateModel, resetModel, setCurrentUser] = useFormData<
    UserTypes
  >(user)
  const {
    state: { account, accounts },
    dispatch,
  } = useContext(AccountsContext)
  const [rememberSelectedFeatures, setRememberSelectedFeatures] = useState<
    string[]
  >([])

  const onSubmitHandler = (e) => {
    e.preventDefault()
    if (onSubmit && account)
      onSubmit({ ...currentUser, account: account?.name })
  }

  const selectedAccount = useMemo(() => {
    if (accounts.length > 0 && currentUser.account_id) {
      const acc = accounts.find((acc) => +acc.id === +currentUser.account_id)
      if (acc) {
        dispatch(AccountsActions(AccountTypes.SetAccount, { account: acc }))
      }

      return acc
    }
    return null
  }, [currentUser, accounts])

  const availableFeatures = useMemo(() => {
    if (selectedAccount) return resolveAvailableFeatures(selectedAccount)
    return []
  }, [selectedAccount])

  const availableAccounts = useMemo(() => {
    if (accounts.length > 0)
      return accounts.map((x) => ({
        label: x.name,
        value: x.id,
      }))
    return []
  }, [accounts])

  const onUserTypesChange = (selectedTypes) => {
    const types = selectedTypes.map((type) => type.value)

    if (
      types.includes(UserTypeEnum.FITTER) ||
      types.includes(UserTypeEnum.RETAILER)
    ) {
      updateModel("is_token_whitelisted", 1)
    } else {
      updateModel("is_token_whitelisted", 0)
    }

    if (types.includes(UserTypeEnum.ADMIN)) {
      updateModel(
        "userTypes",
        types.filter((x) => x !== UserTypeEnum.ADMIN)
      )
      setRememberSelectedFeatures(currentUser.feature)
      updateModel("is_admin", 1)
      updateModel("feature", [])
    } else {
      updateModel("userTypes", types)
      updateModel("is_admin", 0)
      updateModel("feature", rememberSelectedFeatures)
    }
  }

  const availableUserTypes = useMemo(() => {
    if (selectedAccount?.description === ApiAccountTypeEnum.Installer) {
      const availableUserType = userType.find(
        (opt) => opt.value === UserTypeEnum.FITTER
      )
      onUserTypesChange([availableUserType])
      return [availableUserType]
    }
    return userType
  }, [selectedAccount])

  return (
    <StyledForm onSubmit={onSubmitHandler} {...props}>
      <div className="group">
        <h3 tw="m-0 mb-8">{basicTitle}</h3>
        <FormField label="Email" validationKey="email">
          <ValidationInput
            required
            id="email"
            defaultValue={currentUser.email}
            placeholder="Indtast email"
            onChange={(e) => {
              updateModel("email", e.target.value)
            }}
          />
        </FormField>
        <FormField label="Brugernavn" validationKey="username">
          <ValidationInput
            id="username"
            required
            value={currentUser.username}
            placeholder="Indtast brugernavn"
            onChange={(e) => updateModel("username", e.target.value)}
            onFocus={(e) => {
              if (!currentUser.username || currentUser.username === "")
                updateModel("username", currentUser.email)
            }}
          />
        </FormField>
        <FormField label="Fornavn" validationKey="firstName">
          <ValidationInput
            id="firstName"
            required
            defaultValue={currentUser.firstName}
            placeholder="Indtast fornavn"
            onChange={(e) => updateModel("firstName", e.target.value)}
          />
        </FormField>
        <FormField label="Efternavn" validationKey="lastName">
          <ValidationInput
            id="lastName"
            required
            defaultValue={currentUser.lastName}
            placeholder="Indtast efternavn"
            onChange={(e) => updateModel("lastName", e.target.value)}
          />
        </FormField>
        <FormField label="Telefon (valgfri)">
          <ValidationInput
            defaultValue={currentUser.phoneNumber}
            placeholder="Indtast telefon"
            onChange={(e) => updateModel("phoneNumber", e.target.value)}
          />
        </FormField>
      </div>
      {showSettings && (
        <div className="group">
          <h3 tw="lg:(m-0 mb-8)">{settingsTitle}</h3>
          <FormField
            label="Tilknyttet konto*"
            tw="z-50 relative"
            css={!!user?.account_id ? tw`bg-brand-gray-lighter` : ``}
          >
            <Select
              required
              tw="pt-8"
              placeholder="Vælg en konto"
              options={availableAccounts}
              isDisabled={!!user?.account_id}
              onChange={(opt) => {
                updateModel("account_id", opt.value)
                updateModel("account", opt.label)
              }}
              defaultValue={availableAccounts.find(
                (opt) => +opt.value === +user?.account_id
              )}
            />
          </FormField>
          <FormField
            label="Brugertyper"
            tw="z-40 relative"
            css={
              account?.description === ApiAccountTypeEnum.Installer
                ? tw`bg-brand-gray-lighter`
                : ``
            }
          >
            <Select
              tw="pt-8"
              placeholder="Vælg brugertype"
              options={availableUserTypes}
              isDisabled={account?.description === ApiAccountTypeEnum.Installer}
              isMulti
              onChange={onUserTypesChange}
              defaultValue={
                selectedAccount?.description === ApiAccountTypeEnum.Installer
                  ? userType.find(
                      (opt) => opt.value === ApiAccountTypeEnum.Installer
                    )
                  : userType.filter((opt) =>
                      currentUser.userTypes?.includes(opt.value)
                    )
              }
            />
          </FormField>
          {currentUser.userTypes?.length === 1 &&
            currentUser.userTypes.includes(UserTypeEnum.USER) && (
              <FormField label="Adgange" tw="z-30 relative">
                <Select
                  tw="pt-8"
                  placeholder="Vælg adgange"
                  options={availableFeatures}
                  isMulti
                  onChange={(opt) =>
                    updateModel(
                      "feature",
                      opt.map((o) => o.value)
                    )
                  }
                  defaultValue={availableFeatures.filter((opt) =>
                    currentUser.feature?.includes(opt.value)
                  )}
                />
              </FormField>
            )}
          <FormField tw="border-0">
            <Checkbox
              appearance="toggle"
              checked={currentUser.active === 1}
              onChange={(checked) => updateModel("active", checked ? 1 : 0)}
            >
              Brugeren er{" "}
              {`${currentUser.active === 1 ? `aktiveret` : `deaktiveret`}`}
            </Checkbox>
          </FormField>
          <FormField tw="border-0">
            <Checkbox
              appearance="toggle"
              checked={currentUser.isAccountAdmin}
              onChange={(checked) => updateModel("isAccountAdmin", checked)}
            >
              Brugeren er{" "}
              {`${
                currentUser.isAccountAdmin
                  ? `kontoadminstrator`
                  : `ikke kontoadminstrator`
              }`}
            </Checkbox>
          </FormField>
        </div>
      )}
      <ButtonGroup
        sticky="bottom"
        tw="px-8 py-4 m-0 bg-white lg:(px-0)"
        css={{ bottom: "6.4rem" }}
      >
        <Button type="button" variant="cancel" onClick={() => navigate(-1)}>
          Annullér
        </Button>
        <Button type="submit" variant="primary">
          {saveButtonText}
        </Button>
      </ButtonGroup>
    </StyledForm>
  )
}
