import React, { useContext, useEffect, useMemo, useRef } from "react"
import "twin.macro"
import {
  FlowHeader,
  ScreenHeader,
  StyledPopoverContentContainer,
} from "./components"
import {
  ExtendedBoolean,
  InstallationScreenEnum,
  InstallationStepStateEnum,
  InstallationStepType,
  InstallationVerificationModel,
  MainSwitchBypassBoolean,
} from "../types"
import { InstallationContext } from "../context"
import { useFileUpload } from "app/FileUpload/hooks"
import { useFormData } from "hooks/useFormData"
import {
  Button,
  ButtonGroup,
  Form,
  FormField,
  Icon,
  Textarea,
  ValidationInput,
} from "@clevertrack/shared"
import { PhotoUpload } from "./components/PhotoUpload"
import Checkbox from "lib/Checkbox"
import { patchVehicle } from "services/vehicles"
import { FormFieldToggle } from "components/FormFieldToggle"
import Accordion from "lib/Accordion"
import { useInstallation } from "../hooks"
import { DeviceTypeCategoryEnum } from "app/Devices/types"
import {
  getInstallationStepState,
  gpsTrackerWithAutoOffSupport,
} from "./helper"
import { debounce, isEqual } from "lodash-es"

const bypassReasons = [
  {
    value: "redundant",
    label: "Er ikke nødvendigt eller bestilt",
  },
  {
    value: "otherPowerSourceFound",
    label: "Der er fundet fast strøm udenom hovedafbryder",
  },
  {
    value: "extraWorkRequired",
    label: "Kræver ekstra arbejde",
  },
]

export const InstallationVerification: React.FC<InstallationStepType> = ({
  onSave,
  ...props
}) => {
  const {
    state: { account, device, installationSteps, currentScreen },
  } = useContext(InstallationContext)
  const [
    currentModel,
    updateModel,
    resetModel,
    setCurrentModel,
    deleteModelKey,
  ] = useFormData<InstallationVerificationModel>(
    installationSteps.find(
      (step) => step.stepID === InstallationScreenEnum.InstallationVerification
    )?.data
  )
  const { deviceTypeCategory, setCurrentScreen } = useInstallation()
  const {
    onUploadDocumentHandler,
    uploadMap,
    removeItemFromUploadMapByKey,
  } = useFileUpload(`${account?.id}/${device?.id}`, account?.id)

  const stepData = useMemo(() => {
    if (installationSteps) {
      const step = installationSteps.find(
        (step) =>
          step.stepID === InstallationScreenEnum.InstallationVerification
      )
      if (step) {
        return step
      }
      return null
    }
    return null
  }, [installationSteps])

  const autoSaveFunc = useRef(
    debounce((newData) => {
      onSave(InstallationScreenEnum.InstallationVerification, newData, null, {
        autoSave: true,
      })
    }, 2000)
  )

  useMemo(() => {
    if (
      currentModel &&
      currentScreen === InstallationScreenEnum.InstallationVerification &&
      !isEqual(stepData?.data, currentModel)
    ) {
      const newData = {
        ...stepData,
        data: currentModel,
        stepState: InstallationStepStateEnum.Incomplete,
      }
      autoSaveFunc.current.cancel()
      autoSaveFunc.current(newData)
    }
  }, [currentModel, stepData, autoSaveFunc, currentScreen])

  const onSubmitHandler = () => {
    if (autoSaveFunc.current) autoSaveFunc.current.cancel()
    const newData = {
      ...stepData,
      data: currentModel,
      stepState: getInstallationStepState(
        currentModel,
        stepData.optionalDataProps
      ),
    }
    const apiSave = currentModel.deviceMountingNote
      ? patchVehicle(
          {
            name: device?.name,
            note: device?.note,
            description: currentModel.deviceMountingNote,
            customer_id: device?.customer_id,
            subscription_type: device?.subscription_type,
            subscription_addon: device?.subscription_addon,
            aempid: device?.aempid,
          },
          device.id
        )
      : null
    onSave(
      InstallationScreenEnum.InstallationVerification,
      newData,
      Promise.all([apiSave])
    )
  }

  const onAcceptFilesHandler = async (files, filename, key) => {
    const result = await onUploadDocumentHandler(files, filename, key)
  }

  useMemo(() => {
    Object.values(uploadMap).map((value) => {
      if (value.key) {
        updateModel(value.key, value.downloadURL)
        updateModel(`${value.key}Path`, value.path)
      }
    })
  }, [uploadMap])

  return (
    <StyledPopoverContentContainer>
      <FlowHeader />
      <ScreenHeader>Montering</ScreenHeader>
      <Form onSubmit={onSubmitHandler} {...props}>
        <div tw="p-4 space-y-8 pb-16">
          {deviceTypeCategory !== DeviceTypeCategoryEnum.Beacon && (
            <>
              <h4>Billede af monteringen</h4>
              <PhotoUpload
                photoPropertyKey="deviceMountingPhoto"
                photoPropertyValue={currentModel?.deviceMountingPhoto}
                photoFilename="device-mounting.jpeg"
                photoPath={currentModel?.deviceMountingPhotoPath}
                photoPathPropertyKey="deviceMountingPhotoPath"
                uploadLabel=""
                uploadDescription={`Tracker${
                  deviceTypeCategory ===
                  DeviceTypeCategoryEnum.GPSTrackerWithBattery
                    ? ``
                    : ` og ledningstilslutning`
                } skal være tydeligt synlig`}
                onUpdateModel={updateModel}
                onAcceptFilesHandler={onAcceptFilesHandler}
              />
              <FormField label="Beskriv hvor enheden er monteret">
                <Textarea
                  defaultValue={currentModel.deviceMountingNote}
                  placeholder="Så enheden kan lokaliseres f.eks. ved flytning"
                  onChange={(e) =>
                    updateModel("deviceMountingNote", e.target.value)
                  }
                />
              </FormField>
            </>
          )}
          {deviceTypeCategory === DeviceTypeCategoryEnum.GPSTrackerWithCAN && (
            <>
              <h4 tw="mb-4">CAN-data læsere</h4>
              <span tw="block">
                Er der monteret én eller to CAN-data læsere?
              </span>
              <div tw="flex items-center space-y-0 space-x-8 mb-12">
                <Checkbox
                  checked={currentModel?.deviceCANDataReaderCount === 1}
                  onChange={() => {
                    const {
                      deviceCAN2DataReaderPhoto,
                      deviceCAN2DataReaderPhotoPath,
                      deviceCAN2DataReaderNote,
                      deviceCAN2HighColor,
                      deviceCAN2LowColor,
                      ...newModel
                    } = currentModel
                    setCurrentModel({
                      ...newModel,
                      deviceCANDataReaderCount: 1,
                    })
                  }}
                  checkboxAppearance="radio"
                >
                  Èn CAN-data læser
                </Checkbox>
                <Checkbox
                  checked={currentModel?.deviceCANDataReaderCount === 2}
                  onChange={() => {
                    updateModel("deviceCANDataReaderCount", 2)
                    updateModel("deviceCAN2DataReaderPhoto", null)
                    updateModel("deviceCAN2DataReaderPhotoPath", null)
                    updateModel("deviceCAN2HighColor", null)
                    updateModel("deviceCAN2LowColor", null)
                  }}
                  checkboxAppearance="radio"
                >
                  To CAN-data læsere
                </Checkbox>
              </div>
              <h4>Billede af CAN1-data læser</h4>
              <PhotoUpload
                photoPropertyKey="deviceCANDataReaderPhoto"
                photoPropertyValue={currentModel?.deviceCANDataReaderPhoto}
                photoFilename="device-can-data-reader-mounting.jpeg"
                photoPath={currentModel?.deviceCANDataReaderPhotoPath}
                photoPathPropertyKey="deviceCANDataReaderPhotoPath"
                uploadLabel=""
                uploadDescription="Tydelige CAN-bus ledningspar med påsat CAN1-data læser"
                onUpdateModel={updateModel}
                onDeletePhoto={() =>
                  removeItemFromUploadMapByKey("deviceCANDataReaderPhoto")
                }
                onAcceptFilesHandler={onAcceptFilesHandler}
              />
              <FormFieldToggle
                untoggleText="Fjern feltet"
                toggleText="Tilføj note til billedet +"
              >
                <FormField label="Note (valgfrit)">
                  <ValidationInput
                    defaultValue={currentModel?.deviceCANDataReaderNote}
                    placeholder="Evt. note til billedet"
                    onChange={(e) =>
                      updateModel("deviceCANDataReaderNote", e.target.value)
                    }
                  />
                </FormField>
              </FormFieldToggle>
              <FormField label="Beskriv farven på CAN1-high">
                <ValidationInput
                  defaultValue={currentModel?.deviceCANHighColor}
                  placeholder="F.eks grøn eller lyse/grå"
                  onChange={(e) =>
                    updateModel("deviceCANHighColor", e.target.value)
                  }
                />
              </FormField>
              <FormField label="Beskriv farven på CAN1-low">
                <ValidationInput
                  defaultValue={currentModel?.deviceCANLowColor}
                  placeholder="F.eks. gul eller lilla/hvid"
                  onChange={(e) =>
                    updateModel("deviceCANLowColor", e.target.value)
                  }
                />
              </FormField>
              {currentModel?.deviceCANDataReaderCount === 2 && (
                <>
                  <h4>Billede af CAN2-data læser</h4>
                  <PhotoUpload
                    photoPropertyKey="deviceCAN2DataReaderPhoto"
                    photoPropertyValue={currentModel?.deviceCAN2DataReaderPhoto}
                    photoFilename="device-can-2-data-reader-mounting.jpeg"
                    photoPath={currentModel?.deviceCAN2DataReaderPhotoPath}
                    photoPathPropertyKey="deviceCAN2DataReaderPhotoPath"
                    uploadLabel=""
                    uploadDescription="Tydelige CAN-bus ledningspar med påsat CAN2-data læser"
                    onUpdateModel={updateModel}
                    onDeletePhoto={() =>
                      removeItemFromUploadMapByKey("deviceCAN2DataReaderPhoto")
                    }
                    onAcceptFilesHandler={onAcceptFilesHandler}
                  />
                  <FormFieldToggle
                    untoggleText="Fjern feltet"
                    toggleText="Tilføj note til billedet +"
                  >
                    <FormField label="Note (valgfrit)">
                      <ValidationInput
                        defaultValue={currentModel?.deviceCAN2DataReaderNote}
                        placeholder="Evt. note til billedet"
                        onChange={(e) =>
                          updateModel(
                            "deviceCAN2DataReaderNote",
                            e.target.value
                          )
                        }
                      />
                    </FormField>
                  </FormFieldToggle>
                  <FormField label="Beskriv farven på CAN2-high">
                    <ValidationInput
                      defaultValue={currentModel?.deviceCAN2HighColor}
                      placeholder="F.eks grøn eller lyse/grå"
                      onChange={(e) =>
                        updateModel("deviceCAN2HighColor", e.target.value)
                      }
                    />
                  </FormField>
                  <FormField label="Beskriv farven på CAN2-low">
                    <ValidationInput
                      defaultValue={currentModel?.deviceCAN2LowColor}
                      placeholder="F.eks. gul eller lilla/hvid"
                      onChange={(e) =>
                        updateModel("deviceCAN2LowColor", e.target.value)
                      }
                    />
                  </FormField>
                </>
              )}
            </>
          )}
          {deviceTypeCategory !== DeviceTypeCategoryEnum.Beacon && (
            <>
              <h4>Billede på afstand af monteringen</h4>
              <PhotoUpload
                photoPropertyKey="devicePlacementPhoto"
                photoPropertyValue={currentModel?.devicePlacementPhoto}
                photoFilename="device-placement.jpeg"
                photoPath={currentModel?.devicePlacementPhotoPath}
                photoPathPropertyKey="devicePlacementPhotoPath"
                uploadLabel=""
                uploadDescription="Så trackerens placering nemt kan identificeres"
                onUpdateModel={updateModel}
                onDeletePhoto={() =>
                  removeItemFromUploadMapByKey("devicePlacementPhoto")
                }
                onAcceptFilesHandler={onAcceptFilesHandler}
              />
              <FormFieldToggle
                untoggleText="Fjern feltet"
                toggleText="Tilføj note til billedet +"
              >
                <FormField label="Note (valgfrit)">
                  <ValidationInput
                    defaultValue={currentModel.devicePlacementNote}
                    placeholder="Evt. note til billedet"
                    onChange={(e) =>
                      updateModel("devicePlacementNote", e.target.value)
                    }
                  />
                </FormField>
              </FormFieldToggle>
            </>
          )}
          <h4>Billede taget udefra</h4>
          <PhotoUpload
            photoPropertyKey="vehicleFrontPhoto"
            photoPropertyValue={currentModel?.vehicleFrontPhoto}
            photoFilename="vehicle-front-photo.jpeg"
            photoPath={currentModel?.vehicleFrontPhotoPath}
            photoPathPropertyKey="vehicleFrontPhotoPath"
            uploadLabel=""
            uploadDescription={`Skal omfatte hele køretøjet, maskinen eller materielet`}
            onUpdateModel={updateModel}
            onDeletePhoto={() =>
              removeItemFromUploadMapByKey("vehicleFrontPhoto")
            }
            onAcceptFilesHandler={onAcceptFilesHandler}
          />
          <FormFieldToggle
            untoggleText="Fjern feltet"
            toggleText="Tilføj note til billedet +"
          >
            <FormField label="Note (valgfrit)">
              <ValidationInput
                defaultValue={currentModel.vehicleFrontNote}
                placeholder="Evt. note til billedet"
                onChange={(e) =>
                  updateModel("vehicleFrontNote", e.target.value)
                }
              />
            </FormField>
          </FormFieldToggle>
          {![
            DeviceTypeCategoryEnum.Beacon,
            DeviceTypeCategoryEnum.GPSTrackerWithBattery,
          ].includes(deviceTypeCategory) && (
            <>
              <h4>Strømkilde</h4>
              <div>
                <span>Er der hovedafbryder?</span>
                <div tw="flex items-center space-y-0 space-x-8 mt-6 mb-12">
                  <Checkbox
                    checked={
                      currentModel?.isMainSwitchPresent === ExtendedBoolean.YES
                    }
                    onChange={() => {
                      updateModel("isMainSwitchPresent", ExtendedBoolean.YES)
                      updateModel("isDeviceBypassingMainSwitch", null)
                    }}
                    checkboxAppearance="radio"
                  >
                    Ja
                  </Checkbox>
                  <Checkbox
                    checked={
                      currentModel?.isMainSwitchPresent === ExtendedBoolean.NO
                    }
                    onChange={() => {
                      const {
                        isMainSwitchControlledByGround,
                        isDeviceBypassingMainSwitch,
                        isDeviceMountedWithIgnitionWireRelay,
                        ...newModel
                      } = currentModel
                      setCurrentModel({
                        ...newModel,
                        isMainSwitchPresent: ExtendedBoolean.NO,
                      })
                    }}
                    checkboxAppearance="radio"
                  >
                    Nej
                  </Checkbox>
                  <Checkbox
                    checked={
                      currentModel?.isMainSwitchPresent ===
                      ExtendedBoolean.UNKNOWN
                    }
                    onChange={() => {
                      const {
                        isMainSwitchControlledByGround,
                        isDeviceBypassingMainSwitch,
                        isDeviceMountedWithIgnitionWireRelay,
                        ...newModel
                      } = currentModel
                      setCurrentModel({
                        ...newModel,
                        isMainSwitchPresent: ExtendedBoolean.UNKNOWN,
                      })
                    }}
                    checkboxAppearance="radio"
                  >
                    Ved ikke
                  </Checkbox>
                </div>
                <Accordion
                  toggled={
                    currentModel?.isMainSwitchPresent === ExtendedBoolean.YES
                  }
                >
                  <span>Får trackeren fast strøm udenom hovedafbryder?</span>
                  <div tw="flex items-center space-y-0 space-x-8 mt-6 mb-12">
                    <Checkbox
                      checked={
                        currentModel?.isDeviceBypassingMainSwitch ===
                        MainSwitchBypassBoolean.YES
                      }
                      onChange={() => {
                        updateModel(
                          "isDeviceBypassingMainSwitch",
                          MainSwitchBypassBoolean.YES
                        )
                        updateModel("isMainSwitchControlledByGround", null)
                      }}
                      checkboxAppearance="radio"
                    >
                      Ja
                    </Checkbox>
                    <Checkbox
                      checked={
                        currentModel?.isDeviceBypassingMainSwitch ===
                        MainSwitchBypassBoolean.NO
                      }
                      onChange={() => {
                        const {
                          isMainSwitchControlledByGround,
                          ...newModel
                        } = currentModel
                        setCurrentModel({
                          ...newModel,
                          isDeviceBypassingMainSwitch:
                            MainSwitchBypassBoolean.NO,
                        })
                      }}
                      checkboxAppearance="radio"
                    >
                      Nej
                    </Checkbox>
                    <Checkbox
                      checked={
                        currentModel?.isDeviceBypassingMainSwitch ===
                        MainSwitchBypassBoolean.POWERBYPASSED
                      }
                      onChange={() => {
                        const {
                          isMainSwitchControlledByGround,
                          ...newModel
                        } = currentModel
                        setCurrentModel({
                          ...newModel,
                          isDeviceBypassingMainSwitch:
                            MainSwitchBypassBoolean.POWERBYPASSED,
                        })
                      }}
                      checkboxAppearance="radio"
                    >
                      Strøm er trukket udenom
                    </Checkbox>
                  </div>
                </Accordion>
                <Accordion
                  toggled={[
                    MainSwitchBypassBoolean.POWERBYPASSED,
                    MainSwitchBypassBoolean.YES,
                  ].includes(currentModel?.isDeviceBypassingMainSwitch)}
                >
                  <span>Er hovedafbryderen stelstyret?</span>
                  <small tw="block opacity-60 mt-2 text-xl">
                    Hvis ja, kan der opstå falsk tænding.
                  </small>
                  <div tw="flex items-center space-y-0 space-x-8 mt-6 mb-12">
                    <Checkbox
                      checked={
                        currentModel?.isMainSwitchControlledByGround === true
                      }
                      onChange={() => {
                        updateModel("isMainSwitchControlledByGround", true)
                        updateModel(
                          "isDeviceMountedWithIgnitionWireRelay",
                          null
                        )
                      }}
                      checkboxAppearance="radio"
                    >
                      Ja
                    </Checkbox>
                    <Checkbox
                      checked={
                        currentModel?.isMainSwitchControlledByGround === false
                      }
                      onChange={() => {
                        const {
                          isDeviceMountedWithIgnitionWireRelay,
                          ...newModel
                        } = currentModel
                        setCurrentModel({
                          ...newModel,
                          isMainSwitchControlledByGround: false,
                        })
                      }}
                      checkboxAppearance="radio"
                    >
                      Nej
                    </Checkbox>
                  </div>
                  <Accordion
                    toggled={
                      currentModel?.isMainSwitchControlledByGround === true
                    }
                  >
                    <span>
                      Er der monteret relæ på trackerens tændingsledning?
                    </span>
                    <div tw="flex items-center space-y-0 space-x-8 mt-6 mb-12">
                      <Checkbox
                        checked={
                          currentModel?.isDeviceMountedWithIgnitionWireRelay ===
                          true
                        }
                        onChange={() =>
                          updateModel(
                            "isDeviceMountedWithIgnitionWireRelay",
                            true
                          )
                        }
                        checkboxAppearance="radio"
                      >
                        Ja
                      </Checkbox>
                      <Checkbox
                        checked={
                          currentModel?.isDeviceMountedWithIgnitionWireRelay ===
                          false
                        }
                        onChange={() =>
                          updateModel(
                            "isDeviceMountedWithIgnitionWireRelay",
                            false
                          )
                        }
                        checkboxAppearance="radio"
                      >
                        Nej
                      </Checkbox>
                    </div>
                  </Accordion>
                </Accordion>
              </div>
            </>
          )}
          {gpsTrackerWithAutoOffSupport.includes(device?.deviceType) && (
            <>
              <h4>Auto-sluk</h4>
              <div>
                <span>Er der monteret auto-sluk på maskinen?</span>
                <div tw="flex items-center space-y-0 space-x-8 mt-6 mb-12">
                  <Checkbox
                    checked={currentModel?.autoOffMounted === true}
                    onChange={() => {
                      updateModel("autoOffMounted", true)
                      updateModel(
                        "autoOffRelayMountPhoto",
                        stepData?.data?.autoOffRelayMountPhoto ?? null
                      )
                      updateModel(
                        "autoOffRelayMountPhotoPath",
                        stepData?.data?.autoOffRelayMountPhotoPath ?? null
                      )
                      updateModel(
                        "autoOffRelayMountNote",
                        stepData?.data?.autoOffRelayMountNote ?? null
                      )
                    }}
                    checkboxAppearance="radio"
                  >
                    Ja
                  </Checkbox>
                  <Checkbox
                    checked={currentModel?.autoOffMounted === false}
                    onChange={() => {
                      const {
                        autoOffRelayMountPhoto,
                        autoOffRelayMountPhotoPath,
                        autoOffRelayMountNote,
                        ...newModel
                      } = currentModel
                      setCurrentModel({
                        ...newModel,
                        autoOffMounted: false,
                      })
                    }}
                    checkboxAppearance="radio"
                  >
                    Nej
                  </Checkbox>
                </div>
                {currentModel?.autoOffMounted === true && (
                  <div tw="space-y-8">
                    <h4>Billede af monteret relæ</h4>
                    <PhotoUpload
                      photoPropertyKey="autoOffRelayMountPhoto"
                      photoPropertyValue={currentModel?.autoOffRelayMountPhoto}
                      photoFilename="auto-off-relay-mount-photo.jpeg"
                      photoPath={currentModel?.autoOffRelayMountPhotoPath}
                      photoPathPropertyKey="autoOffRelayMountPhotoPath"
                      uploadLabel=""
                      uploadDescription="Tydeligt billede af arbejdsrelæ monteret på tændingen"
                      onUpdateModel={updateModel}
                      onDeletePhoto={() =>
                        removeItemFromUploadMapByKey("autoOffRelayMountPhoto")
                      }
                      onAcceptFilesHandler={onAcceptFilesHandler}
                    />
                    <FormFieldToggle
                      untoggleText="Fjern feltet"
                      toggleText="Tilføj note til billedet +"
                      defaultToggled={currentModel?.autoOffRelayMountNote}
                    >
                      <FormField label="Note (valgfrit)" tw="mt-4">
                        <ValidationInput
                          defaultValue={currentModel?.autoOffRelayMountNote}
                          placeholder="Evt. note til billedet"
                          onChange={(e) =>
                            updateModel("autoOffRelayMountNote", e.target.value)
                          }
                        />
                      </FormField>
                    </FormFieldToggle>
                    <h4>Yderligere info</h4>
                    <div>
                      <span>Slukker auto-sluk maskinens display?</span>
                      <div tw="flex items-center space-y-0 space-x-8 mt-6 mb-12">
                        <Checkbox
                          checked={
                            currentModel?.autoOffTurnsOffDisplay === true
                          }
                          onChange={() =>
                            updateModel("autoOffTurnsOffDisplay", true)
                          }
                          checkboxAppearance="radio"
                        >
                          Ja
                        </Checkbox>
                        <Checkbox
                          checked={
                            currentModel?.autoOffTurnsOffDisplay === false
                          }
                          onChange={() =>
                            updateModel("autoOffTurnsOffDisplay", false)
                          }
                          checkboxAppearance="radio"
                        >
                          Nej
                        </Checkbox>
                      </div>
                    </div>
                    <div>
                      <span>Slukker auto-sluk maskinen lys?</span>
                      <div tw="flex items-center space-y-0 space-x-8 mt-6 mb-12">
                        <Checkbox
                          checked={currentModel?.autoOffTurnsOffLight === true}
                          onChange={() =>
                            updateModel("autoOffTurnsOffLight", true)
                          }
                          checkboxAppearance="radio"
                        >
                          Ja
                        </Checkbox>
                        <Checkbox
                          checked={currentModel?.autoOffTurnsOffLight === false}
                          onChange={() =>
                            updateModel("autoOffTurnsOffLight", false)
                          }
                          checkboxAppearance="radio"
                        >
                          Nej
                        </Checkbox>
                      </div>
                    </div>
                    <div>
                      <span>
                        Skal nøglen drejes retur før motoren kan startes igen?
                      </span>
                      <div tw="flex items-center space-y-0 space-x-8 mt-6 mb-12">
                        <Checkbox
                          checked={
                            currentModel?.autoOffRequiresTurnKey === true
                          }
                          onChange={() =>
                            updateModel("autoOffRequiresTurnKey", true)
                          }
                          checkboxAppearance="radio"
                        >
                          Ja
                        </Checkbox>
                        <Checkbox
                          checked={
                            currentModel?.autoOffRequiresTurnKey === false
                          }
                          onChange={() =>
                            updateModel("autoOffRequiresTurnKey", false)
                          }
                          checkboxAppearance="radio"
                        >
                          Nej
                        </Checkbox>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        <ButtonGroup sticky="bottom" tw="bg-white px-4 z-50">
          <Button
            type="button"
            variant="cancel"
            onClick={() => setCurrentScreen(InstallationScreenEnum.Tasks)}
          >
            <span tw="flex items-center">
              <Icon icon="chevron-left" tw="w-4 h-4 mr-2" />
              <span tw="text-xl font-normal">Tilbage</span>
            </span>
          </Button>
          <Button
            type="submit"
            variant="primary"
            disabled={
              stepData &&
              getInstallationStepState(
                currentModel,
                stepData?.optionalDataProps
              ) !== InstallationStepStateEnum.Completed
            }
          >
            Gem
          </Button>
        </ButtonGroup>
      </Form>
    </StyledPopoverContentContainer>
  )
}
